import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { RED } from "shared";
import Section, { BackgroundColor, TextAlign } from "components/section";
import CTA from "components/cta";
import ImageBlock from "components/imageBlock";
import image1 from "images/equipe-etcheverry-mindurry.jpg";
import image2 from "images/centre-de-tri-etcheverry-mindurry.jpg";
export const _frontmatter = {
  "meta": {
    "title": "Etcheverry Mindurry | Une entreprise expérimentée dans la gestion des déchets",
    "description": null
  },
  "disableFooterLink": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Section background={BackgroundColor.DARK} mdxType="Section">
      <h1>{`Une entreprise expérimentée dans la `}<strong style={{
          color: `${RED}`
        }}>{`gestion des déchets`}</strong></h1>
      <p>{`La SAS ETCHEVERRY MINDURRY est installée sur la commune de Bassussarry, en bordure
de la route départementale 254.`}</p>
      <p>{`Entreprise à taille humaine, notre équipe composée de 15 collaborateurs est continuellement
formée pour vous apporter un service de proximité optimal.`}</p>
      <p>{`Forts de nos 20 ans d'expérience dans le secteur de la collecte, du traitement et
de la valorisation des déchets, nos prestations sont adaptées aux professionnels
et aux particuliers.`}</p>
      <p>{`Nous intervenons sur un secteur allant du Pays Basque (Bayonne, Anglet, Biarritz,
Bidart, Saint-Jean-de-Luz, Hendaye, Pays Basque intérieur…) au Sud des Landes
(Dax, Hossegor, Côte Sud des Landes, Saint Geours de Maremne, Magescq, Chalosse).`}</p>
      <p>{`Notre savoir-faire associé à la qualité de nos infrastructures nous permet de prendre en charge vos
déchets non dangereux de la collecte à la revalorisation.`}</p>
    </Section>
    <ImageBlock content={image1} width={100} mdxType="ImageBlock" />
    <Section mdxType="Section">
      <h2>{`Notre centre de tri des déchets`}</h2>
      <p><strong parentName="p">{`Ce que dit la loi : Article L541-2 du Code de l’Environnement`}</strong></p>
      <p><em parentName="p">{`« Tout producteur ou détenteur de déchets est tenu d'en assurer ou d'en
faire assurer la gestion. Tout producteur ou détenteur de déchets est responsable
de la gestion de ces déchets jusqu'à leur élimination ou valorisation finale, même
lorsque le déchet est transféré à des fins de traitement à un tiers. Tout producteur
ou détenteur de déchets s'assure que la personne à qui il les remet est autorisée
à les prendre en charge ».`}</em></p>
      <p>{`D’une surface de 7 000 m`}<sup>{`2`}</sup>{`, nos infrastructures accueillent depuis fin 2016 un centre
de tri des déchets couvert d’une superficie de 700 m`}<sup>{`2`}</sup>{`.`}</p>
      <p>{`Notre installation est référencée : Installation Classée pour la Protection de
l’Environnement (ICPE) en application des rubriques n°2714-2 et n°2716-2 de la
nomenclature prévue à l’article R 511-9 du Code de l’environnement. De ce fait,
les conditions d’exploitation de notre centre de tri sont soumises à un régime
de déclaration auprès de la Préfecture des Pyrénées Atlantiques.`}</p>
      <p>{`Nos installations sont en mesure de recevoir des déchets non dangereux et d’effectuer
efficacement les opérations de tri. Nous respectons une démarche approfondie en
termes de recyclage.`}</p>
      <p>{`Le processus de tri est primordial pour permettre un taux de valorisation des
déchets optimal.`}</p>
      <p>{`Ces déchets triés sont conditionnés dans leurs bennes respectives. Ils sont transportés
vers nos exutoires partenaires qui assurent la valorisation des différents déchets triés.`}</p>
      <p>{`Des récépissés de déclaration d’activité de négoce (N°019DIB/0013) et de transport (N°019/TRD/0049)
par route de déchets non dangereux nous ont été délivrés par la Préfecture des Pyrénées Atlantiques. `}</p>
    </Section>
    <ImageBlock content={image2} width={100} mdxType="ImageBlock" />
    <Section mdxType="Section">
      <h2>{`Service de location de bennes`}</h2>
      <p>{`Notre objectif : vous proposer une location de benne adaptée au volume et à la
nature des déchets de votre chantier.`}</p>
      <p>{`Professionnels ou particuliers, un parc de 250 bennes avec des contenances allant
de 7 m`}<sup>{`3`}</sup>{` à 40 m`}<sup>{`3`}</sup>{` est à votre disposition.`}</p>
      <p>{`Location de benne à l’année ou bien sur une durée déterminée, une analyse de vos
besoins est systématiquement réalisée. Un devis adapté à votre demande vous est
adressé au plus tôt.`}</p>
      <p>{`Cette cotation inclut la pose de la benne, la rotation, l’enlèvement du contenant
ainsi que le traitement des déchets.`}</p>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      